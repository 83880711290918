// import '@babel/polyfill';
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./routes";
import moment from "vue-moment";
import request from "./utils/request";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import VueSweetalert2 from "vue-sweetalert2";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
import Trend from "vuetrend";

Vue.use(Trend);

Vue.use(VXETable);

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.prototype.$axios = request;

Vue.use(VueRouter);

Vue.use(moment);

Vue.component("vue-simple-context-menu", VueSimpleContextMenu);

Vue.use(VueSweetalert2);

const router = new VueRouter({
  mode: "history",
  routes,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
