<template>
    <div class="review_main">
        <span> {{ rrname }} </span>

        <div class="review_table_right" v-for="bottoncolor in reviewtabledata" :key="bottoncolor">
            <div class="review_table_sp1">
                <el-button
                    :type="(bottoncolor.项目可行性评审 == '未评审' ? 'info' : (bottoncolor.项目可行性评审 == '评审通过' ? 'success' : (bottoncolor.项目可行性评审 == '评审中' ? 'primary' : (bottoncolor.项目可行性评审 == '延期' ? 'warning' : (bottoncolor.项目可行性评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select1()">项目可行性评审</el-button>
                <span>
                    <i class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-right"></i></span>
                <el-button
                    :type="(bottoncolor.图片需求评审 == '未评审' ? 'info' : (bottoncolor.图片需求评审 == '评审通过' ? 'success' : (bottoncolor.图片需求评审 == '评审中' ? 'primary' : (bottoncolor.图片需求评审 == '延期' ? 'warning' :  (bottoncolor.图片需求评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select2()">图片需求评审</el-button>
                <span>
                    <i class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-right"></i></span>
                <el-button
                    :type="(bottoncolor.listing评审 == '未评审' ? 'info' : (bottoncolor.listing评审 == '评审通过' ? 'success' : (bottoncolor.listing评审 == '评审中' ? 'primary' : (bottoncolor.listing评审 == '延期' ? 'warning' :  (bottoncolor.listing评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select3()">listing评审</el-button>
                <span>
                    <i class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-right"></i></span>
                <el-button
                    :type="(bottoncolor.广告目标及方案评审 == '未评审' ? 'info' : (bottoncolor.广告目标及方案评审 == '评审通过' ? 'success' : (bottoncolor.广告目标及方案评审 == '评审中' ? 'primary' : (bottoncolor.广告目标及方案评审 == '延期' ? 'warning' :  (bottoncolor.广告目标及方案评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select4()">广告目标及方案评审</el-button>
            </div>
            <div class="review_table_bottom">
                <i class="el-icon-bottom"></i><br /><i class="el-icon-bottom"></i>
            </div>
            <div class="review_table_sp2">
                <el-button
                    :type="(bottoncolor.三个月阶段项目可持续性评审 == '未评审' ? 'info' : (bottoncolor.三个月阶段项目可持续性评审 == '评审通过' ? 'success' : (bottoncolor.三个月阶段项目可持续性评审 == '评审中' ? 'primary' : (bottoncolor.三个月阶段项目可持续性评审 == '延期' ? 'warning' :  (bottoncolor.三个月阶段项目可持续性评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select8()">
                    <div>三个月阶段项目可持<br />续性评审</div>
                </el-button>
                <span>
                    <i class="el-icon-back"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i></span>
                <el-button
                    :type="(bottoncolor.两个月项目可持续性评审 == '未评审' ? 'info' : (bottoncolor.两个月项目可持续性评审 == '评审通过' ? 'success' : (bottoncolor.两个月项目可持续性评审 == '评审中' ? 'primary' : (bottoncolor.两个月项目可持续性评审 == '延期' ? 'warning' :  (bottoncolor.两个月项目可持续性评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select7()">
                    <div>两个月项目可持续性<br />评审</div>
                </el-button>
                <span>
                    <i class="el-icon-back"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i></span>
                        <el-button
                    :type="(bottoncolor.广告第四星期效果评审 == '未评审' ? 'info' : (bottoncolor.广告第四星期效果评审 == '评审通过' ? 'success' : (bottoncolor.广告第四星期效果评审 == '评审中' ? 'primary' : (bottoncolor.广告第四星期效果评审 == '延期' ? 'warning' :  (bottoncolor.广告第四星期效果评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton1" @click="select64()">
                    <div>广告第四星期效<br />果评审</div>
                </el-button>
                        <span>
                            <i class="el-icon-back"></i><i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i></span>
                <el-button
                    :type="(bottoncolor.广告第三星期效果评审 == '未评审' ? 'info' : (bottoncolor.广告第三星期效果评审 == '评审通过' ? 'success' : (bottoncolor.广告第三星期效果评审 == '评审中' ? 'primary' : (bottoncolor.广告第三星期效果评审 == '延期' ? 'warning' :  (bottoncolor.广告第三星期效果评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton1" @click="select63()">
                    <div>广告第三星期效<br />果评审</div>
                </el-button>
                <span>
                    <i class="el-icon-back"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i></span>
                        <el-button
                    :type="(bottoncolor.广告第二星期效果评审 == '未评审' ? 'info' : (bottoncolor.广告第二星期效果评审 == '评审通过' ? 'success' : (bottoncolor.广告第二星期效果评审 == '评审中' ? 'primary' : (bottoncolor.广告第二星期效果评审 == '延期' ? 'warning' :  (bottoncolor.广告第二星期效果评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton1" @click="select62()">
                    <div>广告第二星期效<br />果评审</div>
                </el-button>
                <span>
                    <i class="el-icon-back"></i><i class="el-icon-minus"></i><i class="el-icon-minus"></i></span>
                <el-button
                    :type="(bottoncolor.广告首星期效果评审 == '未评审' ? 'info' : (bottoncolor.广告首星期效果评审 == '评审通过' ? 'success' : (bottoncolor.广告首星期效果评审 == '评审中' ? 'primary' : (bottoncolor.广告首星期效果评审 == '延期' ? 'warning' :  (bottoncolor.广告首星期效果评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select5()"><div>广告首星期效<br />果评审</div></el-button>
            </div>
            <div class="review_table_bottom2">
                |<br />|<br />|<br />|<br />|<br />
            </div>
            <div class="review_table_sp3">
                <span> <i class="el-icon-minus"></i><i class="el-icon-minus"></i> <i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i> <i class="el-icon-minus"></i><i class="el-icon-minus"></i> <i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i> <i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i> <i class="el-icon-minus"></i><i class="el-icon-minus"></i> <i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i> <i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i> <i class="el-icon-minus"></i><i class="el-icon-minus"></i> <i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i> <i class="el-icon-minus"></i><i
                        class="el-icon-minus"></i> <i class="el-icon-minus"></i><i class="el-icon-minus"></i> <i
                        class="el-icon-minus"></i><i class="el-icon-minus"></i><i class="el-icon-right"></i></span>
                <el-button
                    :type="(bottoncolor.项目结项评审 == '未评审' ? 'info' : (bottoncolor.项目结项评审 == '评审通过' ? 'success' : (bottoncolor.项目结项评审 == '评审中' ? 'primary' : (bottoncolor.项目结项评审 == '延期' ? 'warning' :   (bottoncolor.项目结项评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select9()">项目结项评审</el-button>
            </div>
        </div>
        <!-- ---------------------------------------------------------------------------------------------- -->
        <el-dialog :title="title" :visible="dialogVisible1" width="750px" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' :  (form.评审状态 == '延期审核中' ? 'warning' : 'danger')))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容" >
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="600" trigger="click">
                        <el-table :data="gridData">
                            <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                            <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                            <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                            <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" 
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :file-list="fileList" :on-preview="handlePreview" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save_updata_b" :loading="isSending">提交</el-button>
            </span>
        </el-dialog>


        <el-dialog :title="title" :visible="dialogVisible2" width="750px" :before-close="handleClose2">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;" type="info">
                        未评审</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="2" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="3" v-model="form.项目备注" autocomplete="off" placeholder="无备注请标记为‘无’"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目负责人" multiple placeholder="请选择项目负责人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目主管" multiple placeholder="请选择项目主管">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.QA" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="截止时间" prop="截止时间">
                    <el-date-picker v-model="form.截止时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传附件" prop="上传附件">
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :on-preview="handlePreview" :file-list="fileList" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" :loading="isSending">发起评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible3" width="750px" :before-close="handleClose3">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>                                                                                                                                                                                                                                                   
                <el-form-item>
                    <el-popover placement="right" width="600" trigger="click">
                        <el-table :data="gridData">
                            <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                            <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                            <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                            <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" placeholder="无备注请标记为‘无’"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目负责人1" multiple placeholder="请选择项目负责人">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.项目主管1" multiple placeholder="请选择项目主管">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.QA1" multiple placeholder="请选择流量分析">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="截止时间" prop="截止时间">
                    <el-date-picker v-model="form.截止时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传附件" prop="上传附件">
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :on-preview="handlePreview" :file-list="fileList" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="remark"  :loading="isSending">重新发起评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible4" width="750px" :before-close="handleClose4">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;" type="info">
                        未评审</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" placeholder="无备注请标记为‘无’"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
                <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 50%">
                    </el-input>
                </el-form-item>
                <el-form-item label="上传附件" prop="上传附件">
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :on-preview="handlePreview" :file-list="fileList" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save_f" :loading="isSending">发起评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible5" width="750px" :before-close="handleClose5">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容" >
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="600" trigger="click">
                        <el-table :data="gridData">
                            <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                            <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                            <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                            <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" 
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-upload multiple style="margin-left: 30px;width: 195px;" class="upload-demo" ref="upload"
                        :file-list="fileList" :on-preview="handlePreview" action :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传zip文件</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="延期时间" prop="延期时间">
                    <el-date-picker v-model="form.发起延期时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save_updata_b" :loading="isSending">更改备注</el-button>
                <el-button type="primary" @click="late_review_uptime" :loading="isSending">发起延期</el-button>
            </span>
        </el-dialog>





    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            dialogVisible1: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisible4: false,
            form: {},
            reviewtabledata: [],
            size: '',
            title: '',
            visible: false,
            tableData: [],
            tableDataover: [],
            isSending: false,
            psname: {
                项目可行性评审: '项目可行性评审',
                图片需求评审: '图片需求评审',
                listing评审: 'listing评审',
                广告目标及方案评审: '广告目标及方案评审',
                三个月阶段项目可持续性评审: '三个月阶段项目可持续性评审',
                两个月项目可持续性评审: '两个月项目可持续性评审',
                二次备货策略或首月销售情况评审: '二次备货策略或首月销售情况评审',
                广告第四星期效果评审: '广告第四星期效果评审',
                广告第三星期效果评审: '广告第三星期效果评审',
                广告第二星期效果评审: '广告第二星期效果评审',
                广告首星期效果评审: '广告首星期效果评审',
                项目结项评审: '项目结项评审',

            },
            options: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '吴尚熹',
                label: '吴尚熹'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }],
            value: '',
            value1: [],
            value2: [],
            value3: [],
            formLabelWidth: '80px',
            timer: null,
            rrname: '',
            reviewer_dataname: '',
            gridData: [],
            datason: []
        }
    },
    // created(){
    //     const that = this
    //     that.rrname = this.$route.query.reviewname
    //     // this.init(this.rrname)
    // },
    mounted() {
        const that = this
        that.rrname = this.$route.query.reviewname
        this.init()
        // this.init(that.rrname)
        // 接受reviewname的参数，并将他赋给rrname
        // 像后端发送rrname的参数，让后端向数据库执行只接受有关rrname的全部字段并将他返回给前端
        // router.replace({ path: '/dashboard/review/review_table'})
    },
    methods: {
        init() {
            let bbname = this.rrname
            console.log(bbname)
            this.$axios.get(`/vue/review/review_table_all?bbname=${bbname}`).then(res => {
                console.log(res);
                this.reviewtabledata = res.data
            })
                .console(error => {
                    console.log(error)
                })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        select1() {
            let pszt = this.reviewtabledata[0].项目可行性评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.项目可行性评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.项目可行性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.项目可行性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else {
                this.dialogVisible3 = true
                this.title = this.psname.项目可行性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select2() {
            let pszt = this.reviewtabledata[0].图片需求评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.图片需求评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.图片需求评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.图片需求评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } 
             else {
                this.dialogVisible3 = true
                this.title = this.psname.图片需求评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select3() {
            let pszt = this.reviewtabledata[0].listing评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.listing评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select4() {
            let pszt = this.reviewtabledata[0].广告目标及方案评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.广告目标及方案评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.广告目标及方案评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.广告目标及方案评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.广告目标及方案评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select5() {
            let pszt = this.reviewtabledata[0].广告首星期效果评审
            if (pszt == '未评审') {
                this.dialogVisible4 = true
                this.title = this.psname.广告首星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.广告首星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.广告首星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.广告首星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select62() {
            let pszt = this.reviewtabledata[0].广告第二星期效果评审
            if (pszt == '未评审') {
                this.dialogVisible4 = true
                this.form = {}
                this.title = this.psname.广告第二星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.广告第二星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.广告第二星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.广告第二星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select63() {
            let pszt = this.reviewtabledata[0].广告第三星期效果评审
            if (pszt == '未评审') {
                this.dialogVisible4 = true
                this.form = {}
                this.title = this.psname.广告第三星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.广告第三星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.广告第三星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.广告第三星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select64() {
            let pszt = this.reviewtabledata[0].广告第四星期效果评审
            if (pszt == '未评审') {
                this.dialogVisible4 = true
                this.form = {}
                this.title = this.psname.广告第四星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.广告第四星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.广告第四星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.广告第四星期效果评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select7() {
            let pszt = this.reviewtabledata[0].两个月项目可持续性评审
            if (pszt == '未评审') {
                this.dialogVisible4 = true
                this.form = {}
                this.title = this.psname.两个月项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.两个月项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.两个月项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } 
             else {
                this.dialogVisible3 = true
                this.title = this.psname.两个月项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select8() {
            let pszt = this.reviewtabledata[0].三个月阶段项目可持续性评审
            if (pszt == '未评审') {
                this.dialogVisible4 = true
                this.form = {}
                this.title = this.psname.三个月阶段项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.三个月阶段项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.三个月阶段项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.三个月阶段项目可持续性评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select9() {
            let pszt = this.reviewtabledata[0].项目结项评审
            if (pszt == '未评审') {
                this.dialogVisible2 = true
                this.title = this.psname.项目结项评审
                this.form = {}
                this.form.项目名称 = this.reviewtabledata[0].项目名称
                this.form.项目内容 = this.reviewtabledata[0].项目内容
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.form.评审点 = this.title
            } else if (pszt == '评审中' || pszt == '评审通过' || pszt == '延期审核中') {
                this.dialogVisible1 = true
                this.title = this.psname.项目结项评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else if (pszt == '超期') {
                this.dialogVisible5 = true
                this.title = this.psname.项目结项评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }  else {
                this.dialogVisible3 = true
                this.title = this.psname.项目结项评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        handEdit(index, row) {
            this.dialogVisible = true
            this.title = '编辑记录'
            this.form = JSON.parse(JSON.stringify(row))
        },
        reviewer_data(a, b) {
            this.$axios.post(`/vue/review/reviewer_data`, { title: a, rename: b }).then(res => {
                console.log(res)
                this.gridData = res.data
            }).console(error => {
                console.log(error)
            })
        },
        review_data_son(a, b) {
            this.$axios.post(`/vue/review/review_data_son`, { title: a, rename: b }).then(res => {
                console.log(res)
                this.datason = res.data
                this.reread(a, b)
            }).catch(error => {
                console.log("错误：", error);
                if(error == "TypeError: Cannot set properties of undefined (setting '评审点')"){
                    alert('发生错误:=='+error+ '======请先使‘广告目标及方案评审’通过')
                    location.reload()
                }
            });
        },
        reread(a, b) {
            this.form = this.datason[0]
            this.form.评审点 = this.title
            this.reviewer_data(a, b)
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.init()
        },
        handleClose2() {
            this.dialogVisible2 = false
            this.init()
        },
        handleClose3() {
            this.dialogVisible3 = false
            this.init()
        },
        handleClose4() {
            this.dialogVisible4 = false
            this.init()
        },
        handleClose5() {
            this.dialogVisible5 = false
            this.init()
        },
        handlePreview(file) {
            console.log(file);
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        save() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_subbmit', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose2() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error+ '请检查是否填写完整')
                this.isSending = false
            });
        },
        save_updata_b() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_updata_b', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error+ '请检查是否填写完整')
                this.isSending = false
            });
        },
        late_review_uptime() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/late_review_uptime', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose5() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error+ '请检查是否填写完整')
                this.isSending = false
            });
        },
        save_f() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_subbmit_f', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose4() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error + '请检查是否填写无误，广告目标以及方案评审是否已通过')
            });
        },
        remark() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/review_table_remark', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.isSending = false
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose3() }
                    });
                } else {
                    this.isSending = false
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
            }).catch(error => {
                this.isSending = false
                console.log("错误：", error);
                alert('发生错误:=='+error+'请检查是否填写完整')
            });
        },
        beforeUpload(file) {
            let fileObj = file.file
            let fd = new FormData();
            alert('上传中。。。。。。。。。。。');
            fd.append('file', fileObj);//传文件
            fd.append('title', this.title);
            fd.append('name', this.reviewer_dataname)
            this.$axios.post('/vue/review/review_upload', fd).then(res => {
                if (res.code == 200) {
                    alert('上传成功');
                } else {
                    alert('上传失败');
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error)
            });
        },
        httpRequest(param) {
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            data.append("title", this.title);
            data.append("name", this.reviewer_dataname);
            this.$axios.post('/vue/review/review_upload', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    console.log("结果：", res);
                    alert('上传成功')
                } else {
                    alert('上传失败')
                }
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:=='+error)
            });
        },
        register(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    console.log("有效")
                    this.$refs.upload.submit();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        download() {
            let downtitle = this.title
            let downname = this.reviewer_dataname
            let url = "https://kebeidianzi.com/vue/review/review_download/"+downname+'_'+downtitle+'.zip'
            this.$axios.get('/vue/review/review_download/'+downname+'_'+downtitle+'.zip').catch(error => {
                console.log("错误", error)
            })
            window.open(url, '_blank')
        },
    },
    rightclick() {
        this.visible = true
    },
}
</script>
  
<style>
.review_main {
    height: 100%;
    width: 100%;


}

.review_table_right {
    height: 90%;
    width: 86%;
    margin-left: 6%;
    margin-top: 3%;


}

.review_table_sp1 {
    height: 25%;
    width: 100%;
    margin-top: 1%;

}

.review_table_sp2 {
    height: 25%;
    width: 100%;

}

.review_table_sp3 {
    height: 25%;
    width: 93.5%;
    margin-top: 3.5%;
    float: right;

}

.review_botton {
    height: 150px;
    width: 150px;
}
.review_botton1 {
    height: 130px;
    width: 130px;
}

.review_table_bottom {

    height: 8%;
    width: 1%;
    margin-left: 92.5%;
}

.review_table_bottom2 {
    height: 18%;
    width: 0.5%;
    float: left;
    margin-left: 5.95%;


}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
.el-button--cyan.is-active,
.el-button--cyan:active {
  background: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
 
.el-button--cyan:focus,
.el-button--cyan:hover {
  background: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
 
.el-button--cyan {
  background-color: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
</style>