<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">加工质检</el-menu-item>

            </el-menu>
        </div>
        <div class="select_menu">
            <!-- <el-input v-model="input" placeholder="搜索标题和id" style="width: 10%;"></el-input> -->
            <!-- <el-button type="info" @click="init()">信息按钮</el-button> -->
        </div>
        <div class="kanban">
            <div class="kanban_div1">
                <div style="width: 100%;"><span>未加工</span></div>
                <div class="kanban_table1">
                    <el-table :data="tableData" style="width: 100%" :show-header="false" height="100%"
                        @row-contextmenu="rightclick">
                        <el-table-column width="60">
                            <template slot-scope="scope">
                                <div>
                                    <el-avatar size="large">
                                        <p style="font-size: 13px;">{{ scope.row.name }}</p>
                                    </el-avatar>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="标题" width="210">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="handEdit(scope.$index, scope.row)">{{
                                    scope.row.title
                                }}</div>
                                <el-tag :type="bottercold">{{ scope.row.time }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="功能" width="80">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <el-tag style="float: right;"
                                        :type="(scope.row.lv == '较低' ? 'info' : (scope.row.lv == '普通' ? 'success' : (scope.row.lv == '紧急' ? 'danger' : (scope.row.lv == '非常紧急' ? 'warning' : 'danger'))))">
                                        {{ scope.row.lv }}
                                    </el-tag>
                                    <el-popconfirm title="确认开始加工吗？" @confirm="Conditionupdt_y(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-check" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="确认删除吗？" @confirm="handDelete(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-circle-close" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="20">
                        </el-table-column>
                    </el-table>
                </div>
                <el-button icon="el-icon-plus" size="medium" @click="add" style="width: 100%;"></el-button>
                <!-- <el-button size="mini" class="add" @click="add" icon="el-icon-plus"
              >添加一条记录</el-button
            > -->
            </div>
            <div class="kanban_div1">
                <div style="width: 100%;"><span>加工中</span></div>
                <div class="kanban_table1">
                    <el-table :data="tableDataover1" style="width: 100%;" :show-header="false" height="100%" class="warm">
                        <el-table-column width="60">
                            <template slot-scope="scope">
                                <div>
                                    <el-avatar size="large">
                                        <p style="font-size: 13px;">{{ scope.row.name }}</p>
                                    </el-avatar>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="标题" width="220">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="handEdit(scope.$index, scope.row)">{{
                                    scope.row.title
                                }}</div>
                                <el-tag :type="bottercold">{{ scope.row.time }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="功能" width="80">
                            <template slot-scope="scope">
                                <div class="name-wrapper">
                                    <el-tag style="float: right;"
                                        :type="(scope.row.lv == '较低' ? 'info' : (scope.row.lv == '普通' ? 'success' : (scope.row.lv == '紧急' ? 'danger' : (scope.row.lv == '非常紧急' ? 'warning' : 'danger'))))">
                                        {{ scope.row.lv }}
                                    </el-tag>
                                    <el-popconfirm title="确认完成加工吗？" @confirm="Conditionupdt_n(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-check" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="确认删除吗？" @confirm="handDelete(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-circle-close" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="10">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="kanban_div1">
                <div style="width: 100%;"><span>审核中</span></div>
                <div class="kanban_table1">
                    <el-table :data="tableDataover2" style="width: 100%;" :show-header="false" height="100%" class="warm">
                        <el-table-column width="60">
                            <template slot-scope="scope">
                                <div>
                                    <el-avatar size="large">
                                        <p style="font-size: 13px;">{{ scope.row.name }}</p>
                                    </el-avatar>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="标题" width="220">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="handEdit(scope.$index, scope.row)">{{
                                    scope.row.title
                                }}</div>
                                <el-tag :type="bottercold">{{ scope.row.time }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="功能" width="80">
                            <template slot-scope="scope">
                                <div class="name-wrapper">
                                    <el-tag style="float: right;"
                                        :type="(scope.row.lv == '较低' ? 'info' : (scope.row.lv == '普通' ? 'success' : (scope.row.lv == '紧急' ? 'danger' : (scope.row.lv == '非常紧急' ? 'warning' : 'danger'))))">
                                        {{ scope.row.lv }}
                                    </el-tag>
                                    <el-popconfirm title="确认质检通过吗？" @confirm="Conditionupdt_z(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-check" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="确认删除吗？" @confirm="handDelete(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-circle-close" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="10">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="kanban_div1">
                <div style="width: 100%;"><span>已完成</span></div>
                <div class="kanban_table1">
                    <el-table :data="tableDataover3" style="width: 100%;" :show-header="false" height="100%" class="warm">
                        <el-table-column width="60">
                            <template slot-scope="scope">
                                <div>
                                    <el-avatar size="large">
                                        <p style="font-size: 13px;">{{ scope.row.name }}</p>
                                    </el-avatar>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="标题" width="220">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" @click="handEdit(scope.$index, scope.row)">{{
                                    scope.row.title
                                }}</div>
                                <el-tag :type="bottercold">{{ scope.row.time }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="功能" width="80">
                            <template slot-scope="scope">
                                <div class="name-wrapper">
                                    <el-tag style="float: right;"
                                        :type="(scope.row.lv == '较低' ? 'info' : (scope.row.lv == '普通' ? 'success' : (scope.row.lv == '紧急' ? 'danger' : (scope.row.lv == '非常紧急' ? 'warning' : 'danger'))))">
                                        {{ scope.row.lv }}
                                    </el-tag>
                                    <el-popconfirm title="确认取消已完成状态吗？"
                                        @confirm="Conditionupdt_return(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-minus" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="确认删除吗？" @confirm="handDelete(scope.$index, scope.row)">
                                        <el-button size="mini" icon="el-icon-circle-close" circle slot="reference"
                                            style="float: right;"></el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="10">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog :title="title" :visible="dialogVisible" width="650px" :before-close="handleClose">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left">
                <el-form-item label="加工名称" prop="title">
                    <el-input v-model="form.title" autocomplete="off" />
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input v-model="form.remarks" type="textarea" :rows="2" autocomplete="off" />
                </el-form-item>
                <el-form-item label="创建人" prop="创建人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.name" placeholder="请选择创建人">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行人" prop="name">
                    <el-input v-model="form.zname" autocomplete="off" />
                </el-form-item>
                <el-form-item label="加工时间" prop="time">
                    <el-date-picker v-model="form.time" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="优先级" prop="lv">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-select v-model="form.lv" clearable placeholder="请选择优先级">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                            autocomplete="off">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :title="title" :visible="dialogVisible1" width="650px" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
                :inline="true">
                <el-form-item label="加工名称" prop="title">
                    <el-input v-model="form.title" autocomplete="off" :disabled="true" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
                :inline="true">
                <el-form-item label="备注" prop="remarks">
                    <el-input v-model="form.remarks" type="textarea" :rows="2" autocomplete="off" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="primary" slot="reference" plain
                            style="margin-left: 80%;height: 50px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
                :inline="true">
                <el-form-item label="创建人" prop="创建人">
                    <el-input v-model="form.name" autocomplete="off" :disabled="true" />
                </el-form-item>
                <el-form-item label="执行人" prop="执行人">
                    <el-input v-model="form.zname" autocomplete="off" />
                </el-form-item>
                <el-form-item label="加工时间" prop="加工时间">
                    <el-date-picker v-model="form.time" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        placeholder="选择日期" style="width: 85%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="优先级" prop="优先级">
                    <el-select v-model="form.lv" clearable placeholder="请选择优先级" style="width: 60%;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                            autocomplete="off">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-upload style="width: 195px;" class="upload-demo1" ref="upload" :file-list="fileList" action
                        :http-request="httpRequest"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success" :loading="isSending_register"
                            @click="register('form')">上传到服务器</el-button>
                        <div slot="tip" class="el-upload__tip1" style="font-size: 11px;">选取文件后点击上传服务器上传文件(文件名不可含有空格和特殊符号)</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            dialogVisible: false,
            dialogVisible1: false,
            isSending_register: false,
            form: {},
            size: '',
            title: '',
            visible: false,
            tableData: [],
            tableDataover1: [],
            tableDataover2: [],
            tableDataover3: [],
            fileData: [],
            fileList: [],
            rules: {
                title: [{ required: true, message: '输入标题以新建任务', trigger: 'blur' }],
                remarks: [{ required: true, message: '添加备注（若没有请添加“无”）', trigger: 'blur' }],
                name: [{ required: true, message: '请输入你的名字', trigger: 'blur' }],
                time: [{ required: true, message: '请选择加工时间', trigger: 'blur' }],
                lv: [{ required: true, message: '请选择任务优先级', trigger: 'blur' }],
            },
            options1: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '吴尚熹',
                label: '吴尚熹'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }],
            options: [{
                value: '较低',
                label: '较低'
            }, {
                value: '普通',
                label: '普通'
            }, {
                value: '紧急',
                label: '紧急'
            }, {
                value: '非常紧急',
                label: '非常紧急'
            }],
            value: '',
            formLabelWidth: '80px',
            timer: null,
        }
    },

    created() {
        this.init()
    },
    methods: {
        init() {
            this.$axios.get('/vue/purchase_kanban/kanban_all').then(res => {
                console.log(res);
                this.tableData = res.data
            }),
                this.$axios.get('/vue/purchase_kanban/kanban_allover1').then(res => {
                    console.log(res);
                    this.tableDataover1 = res.data
                }),
                this.$axios.get('/vue/purchase_kanban/kanban_allover2').then(res => {
                    console.log(res);
                    this.tableDataover2 = res.data
                }),
                this.$axios.get('/vue/purchase_kanban/kanban_allover3').then(res => {
                    console.log(res);
                    this.tableDataover3 = res.data
                })
                    .console(error => {
                        console.log(error)
                    })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        add() {
            this.dialogVisible = true
            this.title = '创建任务'
            this.form = {}
        },
        handEdit(index, row) {
            this.dialogVisible1 = true
            this.title = '编辑记录'
            this.form = JSON.parse(JSON.stringify(row))
            this.purchase_kanban_filedata(this.form.title, this.form.name)
        },
        handDelete(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.delete(`/vue/purchase_kanban/delete?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            })
        },
        Conditionupdt_y(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_kanban/condition_y?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            })
        },
        Conditionupdt_n(index, row) {
            if (row.img == '无图') {
                alert('请先上传图片/文件')
            }
            else {
                let id = JSON.parse(JSON.stringify(row)).id
                console.log(id)
                this.$axios.post(`/vue/purchase_kanban/condition_n?id=${id}`).then(res => {
                    if (res.code == 200) {
                        this.$notify.success({
                            title: '成功',
                            message: res.msg,
                            duration: 2000
                        })
                        this.init()
                    } else {
                        this.$notify.success({
                            title: '失败',
                            message: res.msg,
                            duration: 2000
                        })
                    }
                })
            }
        },
        Conditionupdt_z(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_kanban/condition_z?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            })
        },
        Conditionupdt_return(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_kanban/condition_return?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            })
        },
        handleClose() {
            this.dialogVisible = false
            this.init()
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.init()
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        save() {
            this.$refs['form'].validate(valid => {     // 判断是否通过验证，根据有无form来判断是添加还是编辑
                if (valid) {
                    console.log(this.form);
                    if ('id' in this.form) {
                        this.$axios.put('/vue/purchase_kanban/update', this.form).then(res => {
                            if (res.code == 200) {
                                let _this = this
                                this.$notify.success({
                                    title: '成功',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose: function () { _this.handleClose() }
                                })
                                this.dialogVisible1 = false
                            } else {
                                this.$notify.error({
                                    title: '错误',
                                    message: res.msg,
                                    duration: 2000
                                })
                            }
                        })
                    } else {
                        this.$axios.post('/vue/purchase_kanban/add', this.form).then(res => {
                            if (res.code == 200) {
                                let _this = this
                                this.$notify.success({
                                    title: '成功',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose: function () { _this.handleClose() }
                                });
                                this.dialogVisible1 = false
                            } else {
                                this.$notify.error({
                                    title: '错误',
                                    message: res.msg,
                                    duration: 2000
                                });
                            }
                        })

                    }
                }
            })
        },
        rightclick() {
            this.visible = true
        },
        handleChange(file, fileList) {
            // 当文件发生改变时，更新 fileList
            this.fileList = fileList;
        },
        handleSuccess() {
            // 上传成功后的回调，可以根据需要清空 fileList
            this.fileList = [];  // 上传成功后清空
        },
        register(formName) {
            this.$refs[formName].validate(jojo => {
                if (jojo) {
                    console.log("有效")
                    this.$refs.upload.submit()

                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        httpRequest(param) {
            this.isSending_register = true
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            data.append("action", this.form.title);
            data.append("name", this.form.name);
            this.$axios.post('/vue/purchase_kanban/purchase_kanban_upload', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    console.log("结果：", res);
                    alert('上传成功')
                } else {
                    alert('上传失败')
                }
                this.isSending_register = false
                this.fileList = []
                this.purchase_kanban_filedata(this.form.title, this.form.name)
            }).console(error => {
                console.log("错误：", error);
                alert('发生错误:==' + error)
            });
        },
        download(row) {
            console.log(row)
            let url = "https://kebeidianzi.com/vue/purchase_kanban/purchase_kanban_download/" + row.文件名 + "?name=" + row.加工名称 + "&er=" + row.创建人
            this.$axios.get(`/vue/purchase_kanban/purchase_kanban_download/` + row.文件名, { params: { name: row.加工名称, er: row.创建人 }, responseType: 'blob' }).catch(error => {
                console.log("错误", error)
                // alert('下载发生错误:==' + error)
            })
            window.open(url, '_blank')
        },
        purchase_kanban_filedata(name, er) {
            this.$axios.post(`/vue/purchase_kanban/purchase_kanban_filedata`, { name: name, er: er }).then(res => {
                console.log(res)
                this.fileData = res.data
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        },
        delect_load(row) {
            this.$axios.post(`/vue/purchase_kanban/delect_load`, { name: row.加工名称, filename: row.文件名, er: row.创建人 }).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose() }
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
                this.purchase_kanban_filedata(this.form.title, this.form.name)
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        }
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(253, 253, 253);
}

.kanban {
    /* border: 1px solid red; */
    height: 88.9%;
}

.kanban_div1 {
    width: 20.2%;
    height: 94%;
    margin: 20px;
    float: left;

}

.kanban_table1 {
    width: 100%;
    /* border: 1px solid rgb(0, 255, 136); */
    height: 92%;
    margin-top: 10px;

}
</style>

<!-- <style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style> -->